<template>
  <div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          :total-items="totalItems"
          :headers="headers"
          :items="provinces"
          :search="search"
          :total-pages.sync="totalPages"
          title="Provinsi"
          subtitle="Halaman untuk menambahkan provinsi"
          add-btn="Tambah Data"
          :is-loading="isLoadingData"
          @delete-item="confirmDestroy"
          @edit-item="showFormEdit"
          @add-item="showFormAdd"
          @page="page"
          @change-page="paginationHandler"
          @live-search="searchHandler"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
    <ModalDialog
      :visible="modalDialog"
      :width="width"
      :is-loading-button="isLoadingButton"
      :is-disabled-button="!formValid"
      :header="dialog ==='add'? 'Tambah Provinsi' : 'Edit Provinsi'"
      :text-button="dialog ==='add'? 'Tambah' : 'Update'"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
      @handler-button="handlerButton"
    >
      <template v-slot:body>
        <div>
          <v-text-field
            v-model="province.name"
            label="Nama Provinsi"
            dense
            outlined
          ></v-text-field>
        </div>
        <FileInput
          v-if="dialog =='add'"
          :value="province.logo"
          label="Gambar"
          outlined
          dense
          :disabled="false"
          :prependicon="icons.mdiImageMultipleOutline"
          @input="getFile"
        ></FileInput>
        <FileInput
          v-if="dialog =='edit'"
          v-model="initialFile"
          label="Gambar"
          outlined
          dense
          :disabled="false"
          :prependicon="icons.mdiImageMultipleOutline"
          @input="getFile"
        ></FileInput>
      </template>
    </ModalDialog>
    <ConfirmDialog
      :visible="confirmDialog"
      :is-loading-button="isLoadingButton"
      header="Hapus Provinsi"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    >
    </ConfirmDialog>
  </div>
</template>

<script>
import { mdiImageMultipleOutline } from '@mdi/js'
import ConfirmDialog from '@/views/components/ConfirmDialog.vue'
import DataTablePagination from '@/views/components/DataTablePagination.vue'
import FileInput from '@/views/components/FileInput.vue'
import MainCard from '@/views/components/MainCard.vue'
import ModalDialog from '@/views/components/ModalDialog.vue'
import SkeletonLoaderTable from '@/views/components/SkeletonLoaderTable.vue'

export default {
  name: 'Province',
  components: {
    DataTablePagination,
    ModalDialog,
    MainCard,
    SkeletonLoaderTable,
    ConfirmDialog,
    FileInput,
  },
  data() {
    return {
      service: 'province',
      attrs: {
        class: 'mb-6',
        boilerplate: false,
        elevation: 5,
        types: {},
      },
      icons: {
        mdiImageMultipleOutline,
      },
      search: '',
      dialog: null,
      modalDialog: false,
      width: 600,
      confirmDialog: false,
      page: 1,
      totalPages: 0,
      totalItems: 0,
      loading: true,
      isLoadingTable: true,
      isLoadingData: false,
      isLoadingButton: false,
      isDisabledButton: false,
      provinces: [],
      province: {
        name: '',
        logo: null,
      },
      headers: [
        { text: '#', value: 'index' },
        { text: 'Name', value: 'name' },
        { text: 'Actions', value: 'actions' },
      ],
      filterQuery: {
        province: '',
      },
      formValid: false,
    }
  },
  computed: {
    initialFile: {
      get() {
        if (typeof this.province.logo === 'string') {
          return new File([this.province.logo], this.province.logo, { type: 'text/plain' })
        }

        return this.province.logo
      },
      set() {
        return this.province.logo
      },
    },
  },
  watch: {
    province: {
      handler() {
        const valid = []
        const requiredField = ['name']
        Object.entries(this.province).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValid = !valid.includes(false)
      },
      deep: true,
    },
    page: {
      handler() {
        this.listProvince(this.filterQuery)
      },
    },
  },
  async mounted() {
    this.isLoadingTable = true
    await this.listProvince()
    this.isLoadingTable = false
  },
  methods: {
    paginationHandler(pageNumber) {
      this.page = pageNumber
    },
    async listProvince(params = {}) {
      this.isLoadingData = true
      await this.$services.ApiServices.list(this.service, { ...params, page: this.page }).then(
        ({ data }) => {
          this.provinces = data.data.map((provinces, index) => ({
            ...provinces,
            index: index + 1,
          }))
          this.totalItems = data.meta.total
          this.totalPages = data.meta.last_page
        },
        err => {
          console.error(err)
        },
      )
      this.isLoadingData = false
    },

    resetForm() {
      this.province.name = ''
      this.province.logo = null
    },
    getFile(data) {
      this.province.logo = data
    },
    showFormAdd() {
      this.dialog = 'add'
      this.resetForm()
      this.modalDialog = true
    },

    async add() {
      this.isLoadingButton = true
      this.isDisabledButton = true
      this.formData = new FormData()
      this.formData.append('name', this.province.name)
      this.formData.append('logo', this.province.logo)

      if (this.province.logo === null) {
        this.formData.delete('logo')
      }
      await this.$services.ApiServices.add(this.service, this.formData).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
        },
        err => {
          console.error(err)
        },
      )
      this.isLoadingButton = false
      this.isDisabledButton = false
      this.modalDialog = false
      this.resetForm()
      this.listProvince(this.filterQuery)
    },

    async showFormEdit(uuid) {
      this.dialog = 'edit'
      this.province.uuid = uuid
      this.$services.ApiServices.get(this.service, uuid).then(
        ({ data }) => {
          this.province = data.data
        },
        err => console.error(err),
      )
      this.modalDialog = true
    },
    async update() {
      this.isLoadingButton = true
      this.isDisabledButton = true
      this.formData = new FormData()
      this.formData.append('name', this.province.name)
      if (typeof this.province.logo !== 'string' && this.province.logo !== null) {
        this.formData.append('logo', this.province.logo)
      }
      await this.$services.ApiServices.update(this.service, this.formData, this.province.uuid).then(
        ({ data }) => {
          this.listProvince(this.filterQuery)
          this.resetForm()
          this.modalDialog = false
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
        },
        err => console.error(err),
      )
      this.isLoadingButton = false
      this.isDisabledButton = false
      this.modalDialog = false
    },
    confirmDestroy(uuid) {
      this.confirmDialog = true
      this.province.uuid = uuid
    },

    async destroy() {
      this.isLoadingButton = true
      this.isDisabledButton = true
      await this.$services.ApiServices.destroy(this.service, this.province.uuid).then(
        ({ data }) => {
          this.listProvince(this.filterQuery)
          this.showSnackbar({
            text: data.result.message,
            color: 'error',
          })
        },
        err => console.error(err),
      )
      this.isLoadingButton = false
      this.isDisabledButton = false
      this.confirmDialog = false
    },
    handlerButton() {
      if (this.dialog === 'add') this.add()
      else this.update()
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
    searchHandler(province) {
      this.filterQuery.province = province
      this.page = 1
      this.listProvince(this.filterQuery)
    },
  },
}
</script>

<style>
</style>
